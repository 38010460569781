export interface IPostalAddressSchema {
  "@type": "PostalAddress";
  name: "Gravitywell";
  streetAddress: "Unit 22, Impact Working, Spectrum, Bond Street";
  postalCode: "BS1 3LG";
  addressLocality: "Bristol";
  addressRegion: "Avon";
  addressCountry: "GB";
}

const postalAddressSchema: IPostalAddressSchema = {
  "@type": "PostalAddress",
  "name": "Gravitywell",
  "streetAddress": "Unit 22, Impact Working, Spectrum, Bond Street",
  "postalCode": "BS1 3LG",
  "addressLocality": "Bristol",
  "addressRegion": "Avon",
  "addressCountry": "GB"
};

export default postalAddressSchema;
